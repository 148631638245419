import type { Response } from 'types/responses'
import { ApiClient, apiClient } from 'utils/requests.utils'

const BASE_URL = '/common/privacy_content'

export interface Privacy {
  title: string
  sub_title: string
  main: string
  cookiebotConsentChange: string
  version: string
}

export const createDocPrivacyApi = ({ GET }: ApiClient) => ({
  fetchItem: (params): Promise<Response<Privacy>> => GET(BASE_URL, params),
})

export type DocPrivacyApi = ReturnType<typeof createDocPrivacyApi>

const api = createDocPrivacyApi(apiClient)
export const fetchItem = api.fetchItem
