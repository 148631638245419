import { action, observable, makeObservable } from 'mobx'

class AppStore {
  @observable useCases = []

  @observable loading = true
  @observable layoutSettings = {
    hideHeader: false,
    hideFooter: false,
  }

  @action setLayoutSettings = (settings) => {
    this.layoutSettings = settings
  }

  constructor() {
    makeObservable(this)
  }
}

export default new AppStore()
