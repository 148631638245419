import { action, observable, computed, makeObservable } from 'mobx'

import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import { setTokens, withFingerprint } from 'utils/requests.utils'
import { getAffiliateCookies } from 'utils/affiliatePrograms.utils'
import { getParamsFromUrl } from 'utils/helpers.utils'
import { getParamsFromCookies, removeParamsFromCookies } from 'utils/cookiesConsents.utils'
import { PUBLISHER_OVERVIEW_PATH } from 'constants/routes/publisher.constants'
import { BUTTON_STATUSES } from 'constants/registration.constants'
import { CURRENT_TIMEZONE } from '@elo-kit/constants/dateTime.constants'
import { THEME_TRACKING_COOKIES_PARAMS } from 'constants/themes.constants'
import { TEAM_MEMBER_OVERVIEW_PATH } from 'constants/routes/teamMember.constants'
import { SALES_TEAM_MEMBER_INVITES_PATH } from 'constants/routes/salesTeamMember.constants'
import { ROOT_URL } from 'constants/general.constants'
import { ELO_PUBLISHER_OVERVIEW_PATH } from 'constants/routes/eloPublisher.constants'

import { getSearchParams } from 'utils/queryString.utils'
import { generateShopName } from 'shared/api/shopName.api'
import { PROFILES } from 'constants/profile.constants'
import { DEFAULT_LOCALE } from 'constants/locale.constants'
import { getAllowedBrowserLocale } from 'utils/languages.utils'
import { getCookies, removeCookies } from 'libs/common/cookies'
import { isWindowEnv } from 'utils/env.utils'

import { applyElopagePromo } from '../api/elopagePromos.api'
import * as appUserApi from '../api/user.api'
import sellerStore from './seller.store'

export const PROFILE_TYPES = Object.freeze({
  seller: 'seller',
  publisher: 'publisher',
  eloPublisher: 'ablefy_affiliate',
  ablefyAffiliate: 'ablefy_affiliate',
  teamMember: 'team_member',
  salesTeamMember: 'sales_team_member',
  payer: 'payer',
  client: 'client',
})

class User {
  @observable firstName = ''
  @observable lastName = ''
  @observable phoneNumber = ''
  @observable email = ''
  @observable companyName = ''
  @observable street = ''
  @observable streetNumber = ''
  @observable city = ''
  @observable country = ''
  @observable zip = ''
  @observable positionInCompany = ''
  @observable employees = ''
  @observable password = ''
  @observable username = ''
  @observable withPromoPlan = ''
  @observable token = ''
  @observable profileType = PROFILES.seller
  @observable termsAccepted = false
  @observable subscribed = false

  constructor() {
    makeObservable(this)
  }
}

const MAX_STEP = 2
const MIN_STEP = 0

function userToRequestDataAdapter(user, recaptcha) {
  if (!user) return {}

  const locale =
    getParamsFromUrl(['locale']).locale ?? getCookies('locale') ?? getAllowedBrowserLocale() ?? DEFAULT_LOCALE
  const { refid } = getSearchParams()

  const baseUser = withFingerprint({
    email: user.email,
    password: user.password,
    username: (user.username || '').toLowerCase(),
    withPromoPlan: user.withPromoPlan,
    profileType: user.profileType === PROFILE_TYPES.eloPublisher ? 'elo_publisher' : user.profileType,
    timeZoneName: CURRENT_TIMEZONE,
    locale,
    recaptcha,
    ...(refid && { refid }),
    userProfilesAttributes: [
      {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phoneNumber,
      },
    ],
  })

  switch (user.profileType) {
    case PROFILE_TYPES.seller:
      return {
        ...baseUser,
        subscribed: user.subscribed,
        seller: {
          termsAccepted: 1,
        },
        ...getAffiliateCookies(),
        ...getParamsFromCookies(THEME_TRACKING_COOKIES_PARAMS),
        ...getParamsFromUrl(THEME_TRACKING_COOKIES_PARAMS),
      }
    case PROFILE_TYPES.publisher:
      return {
        ...baseUser,
        userProfilesAttributes: [
          {
            ...baseUser.userProfilesAttributes[0],
            company: user.companyName,
            zip: user.zip,
            countryCode: user.country && user.country.code,
            city: user.city,
            street: user.street,
            streetNumber: user.streetNumber,
          },
        ],
        publisher: {
          termsAccepted: 1,
        },
      }
    case PROFILE_TYPES.eloPublisher:
      return {
        ...baseUser,
        eloPublisher: {
          termsAccepted: 1,
          token: user.token,
        },
      }
    case PROFILE_TYPES.teamMember:
      return {
        ...baseUser,
        userProfilesAttributes: [
          {
            ...baseUser.userProfilesAttributes[0],
            company: user.companyName,
            zip: user.zip,
            countryCode: user.country && user.country.code,
            city: user.city,
            street: user.street,
            streetNumber: user.streetNumber,
          },
        ],
        teamMember: {
          termsAccepted: 1,
        },
      }
    case PROFILE_TYPES.salesTeamMember:
      return {
        ...baseUser,
        userProfilesAttributes: [
          {
            firstName: user.firstName,
            lastName: user.lastName,
          },
        ],
        salesTeamMember: {
          termsAccepted: 1,
          token: user.token,
        },
      }
    default:
      return baseUser
  }
}

class SignUpFormStore {
  @observable currentStep = 0
  @observable emailStatus = {}
  @observable.ref user = new User()
  @observable submitButtonStatus = BUTTON_STATUSES.active
  @observable recaptcha = null

  @computed get emailWarnings() {
    return {
      uniqueness: this.emailStatus.exist && !this.emailStatus.hasSellerProfile,
      registration: this.emailStatus.exist && this.emailStatus.hasSellerProfile,
    }
  }

  @action setStep = (step) => {
    const isInBound = step >= MIN_STEP && step <= MAX_STEP

    if (isInBound) this.currentStep = step
  }

  @action setUser = (user) => {
    this.user = {
      ...this.user,
      ...user,
    }
  }

  @action setRecaptcha = (value) => {
    this.recaptcha = value
  }

  @action clearUser = () => {
    this.user = new User()
  }

  @action clearEmailStatus = () => {
    this.emailStatus = {}
  }

  @action setEmailStatus = (emailStatus) => {
    this.emailStatus = {
      ...this.emailStatus,
      ...emailStatus,
    }
  }

  @action checkEmail = async (email) => {
    if (!email) {
      this.setEmailStatus({
        exist: false,
        valid: false,
        hasSellerProfile: false,
        lastValue: email,
      })
      return this.emailStatus
    }

    if (email === this.emailStatus.lastValue) {
      return {}
    }

    try {
      this.setSubmitButtonStatus(BUTTON_STATUSES.pending)

      const { valid, exist, profileTypes } = await appUserApi.checkEmailUniqueness(email)

      this.setEmailStatus({
        valid,
        exist,
        lastValue: email,
        hasSellerProfile: (profileTypes ?? []).includes(PROFILES.seller),
      })
      return this.emailStatus
    } finally {
      this.setSubmitButtonStatus(BUTTON_STATUSES.active)
    }
  }

  @action setSubmitButtonStatus = (value) => (this.submitButtonStatus = value)

  @action generateUserName = async ({ firstName, lastName }) => {
    this.setSubmitButtonStatus(BUTTON_STATUSES.pending)

    const resp = await generateShopName({
      first_name: firstName,
      last_name: lastName,
    })

    this.setSubmitButtonStatus(BUTTON_STATUSES.active)

    if (resp.success) {
      return resp.shopName
    }

    return ''
  }

  setUserProps = (values) => {
    if (typeof values === 'object') {
      Object.keys(values).forEach((propName) => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.user.hasOwnProperty(propName)) {
          this.setUser({ [propName]: values[propName] })
        }
      })
    }
  }

  setUserPropsFromParams = () => {
    const params = getSearchParams()
    if (typeof params === 'object') {
      Object.keys(params).forEach((propName) => {
        const name = snakeCaseToCamelCase(propName)
        const value = params[propName] === PROFILE_TYPES.client ? PROFILE_TYPES.seller : params[propName]
        // eslint-disable-next-line no-prototype-builtins
        if (this.user.hasOwnProperty(name)) {
          this.setUser({ [name]: value })
        }
      })
    }
  }

  registerUser = async () => {
    const userForRequest = await userToRequestDataAdapter(this.user, this.recaptcha)

    const userSignUpResponse = await appUserApi.signUp(userForRequest)

    removeParamsFromCookies(THEME_TRACKING_COOKIES_PARAMS)

    if (userSignUpResponse.success) {
      await setTokens(userSignUpResponse)

      const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage
      const isSellerRegistration = this.user.profileType === PROFILE_TYPES.seller

      if (isSellerRegistration && sessionStorage) {
        sessionStorage.setItem('new_seller_reg_completed', 'true')
        await sellerStore.logTrafficCookies()
      }
      const elopagePromoToken = getCookies('elopagePromoToken')

      if (elopagePromoToken) {
        const promoToken = `ablefy_promo_token=${elopagePromoToken}`
        const defaultUrl = `${ROOT_URL}/cabinet?${promoToken}`

        const applyPromoResponse = await applyElopagePromo(elopagePromoToken)

        if (applyPromoResponse?.success) {
          const successLandingUrl = getCookies('successLandingUrl')
          const successUrl = successLandingUrl ? `${successLandingUrl}?${promoToken}` : defaultUrl

          window.location.href = successUrl
        } else {
          window.location.href = defaultUrl
        }

        removeCookies('elopagePromoToken')
        removeCookies('successLandingUrl')
      } else {
        switch (this.user.profileType) {
          case 'team_member':
            return window.location.replace(TEAM_MEMBER_OVERVIEW_PATH)
          case 'sales_team_member':
            return window.location.replace(SALES_TEAM_MEMBER_INVITES_PATH)
          case 'publisher':
            return window.location.replace(PUBLISHER_OVERVIEW_PATH)
          case 'elo_publisher':
          case 'ablefy_affiliate':
            return window.location.replace(ELO_PUBLISHER_OVERVIEW_PATH)
          default: {
            return window.location.replace('/cabinet')
          }
        }
      }
    } else {
      this.setSubmitButtonStatus(BUTTON_STATUSES.active)
    }
  }

  @action
  setCurrentStep = (step) => {
    this.setStep(step)
  }

  handleFormSubmit = (values) => {
    this.setSubmitButtonStatus(BUTTON_STATUSES.pending)
    this.setUserProps(values)
    this.setStep(this.currentStep + 1)
    this.setSubmitButtonStatus(BUTTON_STATUSES.active)
    window.scroll(0, 0)
  }

  @action
  handleBackClick = () => this.setStep(this.currentStep - 1)

  @action
  handleAccountCreation = (values) => {
    if (this.submitButtonStatus === BUTTON_STATUSES.active) {
      this.setSubmitButtonStatus(BUTTON_STATUSES.pending)
      this.setUserProps(values)
      this.registerUser()
    }
  }

  @action
  sendMagicLinkToCreateUsername = async (values) => {
    if (this.submitButtonStatus !== BUTTON_STATUSES.active) {
      return
    }

    const { epid } = getSearchParams()

    this.setSubmitButtonStatus(BUTTON_STATUSES.pending)
    try {
      const response = await appUserApi.sendMagicLinkToCreateUsername({
        profileType: 'user',
        termsAccepted: values.termsAccepted,
        email: this.user.email,
        username: values.username,
        epid,
      })

      return response?.success
    } finally {
      this.setSubmitButtonStatus(BUTTON_STATUSES.active)
    }
  }

  constructor() {
    makeObservable(this)
  }
}

export default new SignUpFormStore()
