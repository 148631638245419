import React, { Component, lazy, Suspense } from 'react'
import { inject } from 'mobx-react'
import { Switch, Route, withRouter } from 'react-router-dom'

import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { cancelPendingRequests } from 'utils/request-cancelation.utils'

import {
  APP_ROOT_PATH,
  APP_TERMS_PATH,
  APP_PRIVACY_PATH,
  APP_IMPRINT_PATH,
  APP_EMAIL_UNSUBSCRIBE_PATH,
  APP_USERS_CONFIRMATION_PATH,
  APP_USERS_CONFIRMATION_NEW_PATH,
  APP_USERS_SIGN_IN_PATH,
  APP_USERS_SIGN_UP_PATH,
  APP_USERS_PASSWORD_NEW_PATH,
  APP_USERS_PASSWORD_EDIT_PATH,
  APP_USERS_ELO_PUBLISHER_EDIT_PATH,
  APP_EMAIL_LOGS_PATH,
  APP_SLUG_PATH,
  APP_ELOPAGE_PROMO_PATH,
  APP_ELOPAGE_PROMO_PATH_DEPRECATED,
  APP_PLANS_PATH,
  APP_USERS_SIGN_UP_QUESTIONS_PATH,
} from 'constants/routes/app.constants'

const Terms = lazy(() => import('./screens/terms/Terms'))
const Imprint = lazy(() => import('./screens/imprint/Imprint'))
const Privacy = lazy(() => import('./screens/privacy/Privacy'))
const SignInForm = lazy(() => import('./screens/SignInForm'))
const SignUpForm = lazy(() => import('./screens/sign-up/SignUpForm'))
const SignUpQuestionsForm = lazy(() => import('./screens/sign-up/questions-form/SignUpQuestionsForm'))
const ForgotPasswordForm = lazy(() => import('./screens/forgot-password/ForgotPasswordForm'))
const NewPasswordForm = lazy(() => import('./screens/forgot-password/NewPasswordForm'))
const NewConfirmationForm = lazy(() => import('./screens/confirmation-form/NewConfirmationForm'))
const ConfirmationHandler = lazy(() => import('./screens/confirmation-form/ConfirmationHandler'))
const Landing = lazy(() => import('./screens/landing/Landing'))
const Plans = lazy(() => import('./screens/plans/Plans'))
const CustomLanding = lazy(() => import('./screens/CustomLanding'))
const EmailPreview = lazy(() => import('./screens/EmailPreview'))
const MailingUnsubscribe = lazy(() => import('./screens/mailing-unsubscribe/MailingUnsubscribe'))
const EloPublisherForm = lazy(() => import('./screens/sign-up/elo-publisher/EloPublisherForm'))
const ElopagePromos = lazy(() => import('./screens/ElopagePromos'))
const ElopagePromosDeprecated = lazy(() => import('./screens/ElopagePromosDeprecated'))

import LoadingFallback from 'ui/loading-fallback/LoadingFallback'

@inject('userStore')
class LoginRoute extends Component {
  getCabinetLink = () => {
    const { userStore, location } = this.props

    const params = new URLSearchParams(location.search)
    if (params.has('locale')) {
      params.delete('locale')
    }
    const searchParams = params.toString()
    const cabinetParams = searchParams ? `?${searchParams}` : ''

    return userStore.getCabinetLink(cabinetParams)
  }

  render() {
    const { component: Component, ...rest } = this.props
    const loggedIn = rest.userStore.item.id

    return (
      <Route
        {...rest}
        render={(props) =>
          loggedIn ? (
            <Route
              render={() => {
                window.location.href = this.getCabinetLink()
                return <LoadingMask />
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    )
  }
}

@withRouter
class Routes extends Component {
  constructor(props) {
    super(props)
    const { history } = this.props

    history.listen((location) => {
      cancelPendingRequests(location)
      window.scrollTo(0, 0)
    })
  }

  render() {
    return (
      <Suspense fallback={<LoadingFallback type='app' />}>
        <Switch>
          <Route exact path={APP_ROOT_PATH} component={Landing} />
          <Route exact path={APP_PLANS_PATH} component={Plans} />
          <Route exact path={APP_TERMS_PATH} component={Terms} />
          <Route exact path={APP_PRIVACY_PATH} component={Privacy} />
          <Route exact path={APP_IMPRINT_PATH} component={Imprint} />
          <Route exact path={APP_EMAIL_UNSUBSCRIBE_PATH} component={MailingUnsubscribe} />
          <Route exact path={APP_USERS_CONFIRMATION_PATH} component={ConfirmationHandler} />
          <Route exact path={APP_USERS_CONFIRMATION_NEW_PATH} component={NewConfirmationForm} />
          <LoginRoute exact path={APP_USERS_SIGN_IN_PATH} component={SignInForm} />
          <LoginRoute exact path={APP_USERS_SIGN_UP_PATH} component={SignUpForm} />
          <LoginRoute exact path={APP_USERS_PASSWORD_NEW_PATH} component={ForgotPasswordForm} />
          <LoginRoute exact path={APP_USERS_PASSWORD_EDIT_PATH} component={NewPasswordForm} />
          <Route exact path={APP_USERS_ELO_PUBLISHER_EDIT_PATH} component={EloPublisherForm} />
          <Route exact path={APP_EMAIL_LOGS_PATH} component={EmailPreview} />
          <Route exact path={APP_USERS_SIGN_UP_QUESTIONS_PATH} component={SignUpQuestionsForm} />
          <Route exact path={APP_SLUG_PATH} component={CustomLanding} />
          <Route exact path={APP_ELOPAGE_PROMO_PATH} component={ElopagePromos} />
          <Route exact path={APP_ELOPAGE_PROMO_PATH_DEPRECATED} component={ElopagePromosDeprecated} />
        </Switch>
      </Suspense>
    )
  }
}

export default Routes
