import { computed, makeObservable } from 'mobx'

import {
  filterBySublist,
  formatCountryListOptions,
  formatCountryListOptionsForSignUp,
  isEqualCH,
} from 'utils/countries.utils'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { EVENT_COUNTRIES } from 'constants/countries.constants'
import { Nullable, SelectOption } from 'types/helpers'

import { CountriesApi, Country, createCountriesApi } from '../api/countries.api'
import SharedStore from './shared.store'

type CountryOption = SelectOption<Country>

const defaultCountry: Country = {
  id: 'DE',
  name: 'Germany',
  alpha2: 'DE',
  euMember: true,
  region: '',
}

export class CountriesStore extends SharedStore<any> {
  storeName = 'CountriesStore'
  declare childApi: CountriesApi

  private memoCountry: Nullable<Country> = null

  constructor(root?: ShopRootStore) {
    super()
    this.childApi = createCountriesApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }

  @computed get euCountries() {
    return this.list.filter(({ euMember, alpha2 }) => euMember || isEqualCH(alpha2))
  }

  @computed get withoutChEuCountries() {
    return this.list.filter(({ euMember }) => euMember)
  }

  @computed get withChNonEuCountries() {
    return this.list.filter(({ euMember }) => !euMember)
  }

  @computed get nonEuCountries() {
    return this.list.filter(({ euMember, alpha2 }) => !euMember && !isEqualCH(alpha2))
  }

  @computed get countrySelectOptionsForSignUp() {
    return formatCountryListOptionsForSignUp(this.list)
  }

  @computed get countrySelectOptions() {
    return formatCountryListOptions(this.list)
  }

  eventCountries(additionalCountries = []) {
    return filterBySublist(this.list, [...additionalCountries, ...EVENT_COUNTRIES])
  }

  @computed get alpha2CountryMap() {
    return this.list.reduce((acc, country) => {
      acc[country.alpha2] = country
      return acc
    }, {})
  }

  getCountry = (id: string): Country => {
    if (this.memoCountry?.id === id) {
      return this.memoCountry
    }

    const country = this.list.find((country) => country.id === id)
    this.memoCountry = country

    if (!country) {
      console.error(`Country with id ${id} not found`)
    }

    return country ?? defaultCountry
  }

  getCountrySelectOption = (id: string): CountryOption => {
    const country = this.getCountry(id)

    return {
      label: country?.name,
      value: country?.alpha2,
      ...country,
    }
  }
}

export default new CountriesStore()
