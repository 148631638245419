import type { Response, PaginatedResponse } from 'types/responses'

import { FromSwagger } from 'types/helpers'
import { CountryAttributes, StateAttributes } from 'types/__generated__/api'

export type Country = FromSwagger<CountryAttributes>
export type State = FromSwagger<StateAttributes>

const BASE_URL = '/common/countries'

export const createCountriesApi = ({ GET }) => ({
  fetchList: (data): Promise<PaginatedResponse<Country[]>> => GET(BASE_URL, data),

  fetchItem: (id: number, data): Promise<Response<Country>> => GET(`${BASE_URL}/${id}`, data),
})

export type CountriesApi = ReturnType<typeof createCountriesApi>
