import { observable, action, makeObservable } from 'mobx'

import * as api from '../api/booking.api'

export class BookingStore {
  @observable loading = true

  @action toggleLoading = (loading) => (this.loading = loading)

  bookOption = async (optionId) => {
    this.toggleLoading(true)
    const resp = await api.bookApp({ optionId })
    this.toggleLoading(false)
    return resp
  }

  bookPlan = async (planId) => {
    this.toggleLoading(true)
    const resp = await api.bookPlan({ planId })
    this.toggleLoading(false)
    return resp
  }

  cancelApp = async (data) => {
    this.toggleLoading(true)
    const resp = await api.cancelApp(data)
    this.toggleLoading(false)
    return resp
  }

  subscribe = async (data) => {
    this.toggleLoading(true)
    const resp = await api.subscribe(data)
    this.toggleLoading(false)
    return resp
  }

  changePaymethod = async (data) => {
    this.toggleLoading(true)
    const resp = await api.changePaymethod(data)
    this.toggleLoading(false)
    return resp
  }

  fetchInitData = async (data) => {
    this.toggleLoading(true)
    const resp = await api.fetchData(data)
    this.toggleLoading(false)
    return resp
  }

  charge = async (data) => {
    this.toggleLoading(true)
    const resp = await api.charge(data)
    this.toggleLoading(false)
    return resp
  }

  syncSubsPayment = async (data, subsPaymentId) => {
    this.toggleLoading(true)
    const resp = await api.syncSubsPayment(data, subsPaymentId)
    this.toggleLoading(false)
    return resp
  }

  constructor() {
    makeObservable(this)
  }
}

export default new BookingStore()
