import { makeObservable } from 'mobx'

import { getCookies } from 'libs/common/cookies'

import { postTrafficCookies } from 'shared/api/trafficSourceCookies.api'
import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/seller.api'

export class SellerStore extends SharedStore<api.Seller> {
  storeName = 'SellerStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  constructor() {
    super()

    makeObservable(this)
  }

  async logTrafficCookies() {
    const cookies = getCookies('initialTrafficSource', false)

    if (!cookies) {
      return
    }

    if (!this.item.id) {
      this.setExpands(['user_profile'])
      await this.fetchItem()
    }

    await postTrafficCookies({ value: cookies, profile_id: this.item.id })
  }
}

export default new SellerStore()
