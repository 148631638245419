import { override } from 'mobx'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/elopagePromos.api'

class ElopagePromosStore extends SharedStore<any> {
  storeName = 'ElopagePromosStore'
  childApi = api

  @override
  fetchItem(token) {
    return api.fetchItem(token)
  }

  applyElopagePromo = (token) => api.applyElopagePromo(token)
}

export default new ElopagePromosStore()
