import React from 'react'
import { withRouter } from 'react-router-dom'

import { COPYRIGHT, ROOT_URL } from 'constants/general.constants'

import {
  APP_TERMS_PATH,
  APP_PRIVACY_PATH,
  APP_IMPRINT_PATH,
  APP_USERS_ELO_PUBLISHER_EDIT_PATH,
} from 'constants/routes/app.constants'

import './app-footer.scss'

const AppFooter = withRouter(({ location }) => {
  const FOOTER_LINKS = {
    imprint: `${ROOT_URL}${APP_IMPRINT_PATH}`,
    privacy: `${ROOT_URL}${APP_PRIVACY_PATH}`,
    terms: `${ROOT_URL}${APP_TERMS_PATH}${
      location.pathname === APP_USERS_ELO_PUBLISHER_EDIT_PATH ? '?form=ablefy_affiliate' : ''
    }`,
  }
  return (
    <div className='app-footer'>
      <div className='app-footer__links'>
        <a href={FOOTER_LINKS.terms}>{I18n.t('react.app.footer.terms')}</a>
        <a href={FOOTER_LINKS.privacy}>{I18n.t('react.app.footer.privacy')}</a>
        <a href={FOOTER_LINKS.imprint}>{I18n.t('react.app.footer.imprint')}</a>
      </div>

      <div className='app-footer__copyright'>{COPYRIGHT}</div>
    </div>
  )
})

export default AppFooter
