import { camelToSnakeCase, snakeCaseToCamelCase, capitalize } from 'utils/nameStyle.utils'
import { ALLOWED_LANGUAGES_OPTIONS_KEYS } from 'constants/locale.constants'
import { LANGUAGES } from 'constants/general.constants'

export const getUsedTranslations = (data = {}, searchValue = 'name') =>
  Object.keys(data).reduce((item, value) => {
    if (value.includes(searchValue)) {
      const key = camelToSnakeCase(value.slice(searchValue.length))
      if (key && data[value]) item = [...item, key]
    }
    return item
  }, [])

export const getKeyWithTranslation = (name = '', langKey) => `${name}${snakeCaseToCamelCase(capitalize(langKey))}`

export const getAllowedBrowserLocale = () => {
  const browserLang = navigator.language.split('-')[0]

  return ALLOWED_LANGUAGES_OPTIONS_KEYS.find((lang) => lang === browserLang)
}

export const getChiliPiperLanguageCode = () => {
  let languageCode = 'en_US'

  switch (I18n.locale) {
    case LANGUAGES.en.value:
      languageCode = 'en_US'
      break
    case LANGUAGES.de.value:
      languageCode = 'de_DE'
      break
    default:
      break
  }

  return languageCode
}

export const sortLangugesWithDefault = (languges, defaultLocale) => {
  if (Array.isArray(languges) && !languges.includes(defaultLocale)) {
    return languges
  } else if (typeof languges[0] === 'string') {
    return [languges.find((lang) => lang === defaultLocale), ...languges.filter((lang) => lang !== defaultLocale)]
  } else if (Array.isArray(languges)) {
    return [
      languges.find((lang) => lang.value === defaultLocale),
      ...languges.filter((lang) => lang.value !== defaultLocale),
    ]
  }
}
