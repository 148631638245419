import type { Response } from 'types/responses'
import { ApiClient, apiClient } from 'utils/requests.utils'

const BASE_URL = '/common/imprint_content'

export interface Imprint {
  title: string
  details: string
}

export const createDocImprintApi = ({ GET }: ApiClient) => ({
  fetchItem: (params): Promise<Response<Imprint>> => GET(BASE_URL, params),
})

export type DocImprintApi = ReturnType<typeof createDocImprintApi>
const api = createDocImprintApi(apiClient)
export const fetchItem = api.fetchItem
