import { GET, POST } from 'utils/requests.utils'

const BASE_URL = '/cabinet/billing'

export const bookApp = (data) => POST(`${BASE_URL}/book_app`, data)

export const cancelApp = (data) => POST(`${BASE_URL}/cancel_app`, data)

export const bookPlan = (data) => POST(`${BASE_URL}/book_plan`, data)

export const subscribe = (data) => POST(`${BASE_URL}/subscribe`, data)

export const changePaymethod = (data) => POST(`${BASE_URL}/change_paymethod`, data)

export const fetchData = () => GET(`${BASE_URL}/init_data`)

export const charge = (data) => POST(`${BASE_URL}/charge`, data)

export const syncSubsPayment = (data, subsPaymentId) => POST(`${BASE_URL}/sync_subs_payment?id=${subsPaymentId}`, data)
