import { action, observable, makeObservable } from 'mobx'

import { fetchItem } from '../api/emails.api'

class EmailStore {
  @observable loading = false
  @observable html = ''

  @action toggleLoading = () => (this.loading = !this.loading)
  @action setHtml = (html) => (this.html = html)

  @action
  async fetchItem(token) {
    this.toggleLoading()

    const resp = await fetchItem(token)

    if (resp.data?.html) {
      this.setHtml(resp.data.html)
    }

    this.toggleLoading()

    return resp
  }

  constructor() {
    makeObservable(this)
  }
}

export default new EmailStore()
