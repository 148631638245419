import { GET, POST } from 'utils/requests.utils'
import { Response } from 'types/responses'

const BASE_URL = '/common/survey'

// TODO:
interface Survey {
  foo: any
}

export const fetchList = (data) => GET<Response<Survey>>(BASE_URL, data)

export const sendQuestions = (data) => POST(BASE_URL, data)
