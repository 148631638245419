import { GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/seller_settings'

export const fetchItem = (id, data) => GET(BASE_URL, data)

export const updateItem = (data) => PUT(BASE_URL, data)

export const createWistiaProject = () => POST(`${BASE_URL}/create_wistia_project`)

export const fetchIntegrationsList = (data) => GET(`/cabinet/integrations/list`, data)

export const fetchIntegrationsTags = (data) => GET(`/cabinet/integrations/tags`, data)
