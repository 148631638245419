import { makeObservable } from 'mobx'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/survey.api'

class SurveyStore extends SharedStore {
  childApi = api
  sendQuestions = (token) => api.sendQuestions(token)

  constructor() {
    super()
    makeObservable(this)
  }
}

export default new SurveyStore()
