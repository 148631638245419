import React, { Component, Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import { observer, Provider as MobxProvider } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { setCookie, deleteCookie } from 'cookies-next'

import 'libs/app' // order import is important (I18n should be always on the top)
import 'libs/common/analytics'

import '../../../styles/app'

import { initSentry } from 'libs/common/sentry'

import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'
import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes/app.constants'

import { THEME_TRACKING_COOKIES_PARAMS, THEME_SHARING_PARAMS } from 'constants/themes.constants'
import { ELOPAGE_COOKIEBOT_CONFIG, APP_NAMES } from 'constants/general.constants'

import { setCookiesFromUrlParams, getCookiebotScript } from 'utils/cookiesConsents.utils'
import { appendScript } from 'utils/dom.utils'
import { setAffiliateCookies } from 'utils/affiliatePrograms.utils'

import { LoadingMask, CookiesConsentFloatingBtn, I18nProvider } from '@elo-kit/components'

import { ToastContainer } from 'react-toastify'
import stores from './stores'
import Routes from './routes'

import AppHeader from './components/header/AppHeader'
import AppFooter from './components/footer/AppFooter'

initSentry()

const applyConsent = () => {
  const query = queryString.parse(window.location.search) || {}

  if (query['cc']) {
    setCookie('CookieConsent', decodeURIComponent(query['cc']))
    const newUrl = new URL(window.location.href)
    newUrl.searchParams.delete('cc')
    window.history.replaceState({}, '', newUrl.toString())
  }

  if (query['its']) {
    deleteCookie('initialTrafficSource', { domain: '.ablefy.io' })
    setCookie('initialTrafficSource', decodeURIComponent(query['its']))
    const newUrl = new URL(window.location.href)
    newUrl.searchParams.delete('its')
    window.history.replaceState({}, '', newUrl.toString())
  }
}

@observer
class Main extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cookiebotInitialized: false,
      cookiebotDialogShown: true,
    }
  }

  componentDidMount() {
    const { userStore, i18nStore } = stores

    applyConsent()

    i18nStore.init({ appName: APP_NAMES.app })

    Promise.all([userStore.fetchItem(), i18nStore.fetchTranslationsNew()])
    const { src, attributes } = getCookiebotScript(
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotId,
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotMode
    )
    appendScript(src, attributes, false)
    setCookiesFromUrlParams([...THEME_SHARING_PARAMS, ...THEME_TRACKING_COOKIES_PARAMS])
    setAffiliateCookies()

    /* Configure event listener for Cookiebot */
    window.addEventListener('CookiebotOnLoad', this.handleCookiebotOnLoad)
    window.addEventListener('CookiebotOnAccept', this.handleCookiebotDecided)
    window.addEventListener('CookiebotOnDecline', this.handleCookiebotDecided)
    window.addEventListener('CookiebotOnDialogDisplay', this.handleCookiebotOnDialogShown)
  }

  componentWillUnmount() {
    window.removeEventListener('CookiebotOnLoad', this.handleCookiebotOnLoad)
    window.removeEventListener('CookiebotOnAccept', this.handleCookiebotDecided)
    window.removeEventListener('CookiebotOnDecline', this.handleCookiebotDecided)
    window.removeEventListener('CookiebotOnDialogDisplay', this.handleCookiebotOnDialogShown)
  }

  handleCookiebotOnLoad = () => {
    this.setState({
      cookiebotInitialized: true,
    })
  }

  handleCookiebotOnDialogShown = () => {
    this.setState({
      cookiebotDialogShown: true,
    })
  }

  handleCookiebotDecided = () => {
    this.setState({
      cookiebotDialogShown: false,
    })
  }

  handleCookiebotRenew = () => {
    const { cookiebotInitialized } = this.state

    if (cookiebotInitialized) {
      window.Cookiebot.renew()
    }
  }

  render() {
    const { appStore, userStore, i18nStore } = stores
    const isRootLoading = userStore.loading || i18nStore.isTranslationsLoading

    if (isRootLoading) {
      return <LoadingMask />
    }

    const { layoutSettings } = appStore
    const { hideHeader, hideFooter } = layoutSettings
    const { cookiebotDialogShown } = this.state
    const excludeFromIndex = window.location.pathname === APP_USERS_SIGN_IN_PATH

    return (
      <I18nProvider>
        <MobxProvider {...stores}>
          <BrowserRouter>
            <Fragment>
              <Helmet htmlAttributes={{ lang: I18n.locale }}>
                <title>{I18n.t('react.app.seo.title')}</title>
                <meta name='description' content={I18n.t('react.app.seo.description')} />
                <meta name='og:description' content={I18n.t('react.app.seo.description')} />
                {excludeFromIndex && <meta name='robots' content='noindex' />}
              </Helmet>
              <div className='app'>
                {!hideHeader && <AppHeader />}
                <div className='app-content'>
                  <Routes />
                </div>
                {!hideFooter && <AppFooter />}
              </div>
              <AdminMode userEmail={userStore.item.email} />
              <ApiBranch />
              {!cookiebotDialogShown && <CookiesConsentFloatingBtn onBtnClick={this.handleCookiebotRenew} />}
              <div id='modal-root' /> {/* Should refactor this and delete this root, used for old modal */}
            </Fragment>

            <ToastContainer />
          </BrowserRouter>
        </MobxProvider>
      </I18nProvider>
    )
  }
}

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <Main />
  </ErrorBoundary>
  // </React.StrictMode>
)
