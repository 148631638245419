import type { Response } from 'types/responses'
import { ApiClient, apiClient } from 'utils/requests.utils'

const BASE_URL = '/common/terms_and_conditions_contents'

export interface Terms {
  title: string
  sub_title: string
}

export const createDocTermsApi = ({ GET }: ApiClient) => ({
  fetchItem: (params): Promise<Response<Terms>> => GET(BASE_URL, params),
})

export type DocTermsApi = ReturnType<typeof createDocTermsApi>
const api = createDocTermsApi(apiClient)
export const fetchItem = api.fetchItem
